import React, { useRef, useState } from "react";
import ReactModal from 'react-modal';

import "./desuscribe.css"

function Desubscribe(props){
    const [email, setEmail] = useState();
    const emailRef = useRef(null);

    const [message, setMessage] = useState("");

    const [openModal, setOpenModal] = useState(false)

    function validate(){
        if(!emailRef.current.checkValidity()){
            emailRef.current.setAttribute('aria-invalid', emailRef.current.reportValidity());
        }else{
            setOpenModal(true)
        }
    }

    function desuscribirse(){
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email : email
            })
        };
    
        fetch("https://raknuuaback.onrender.com/customer", requestOptions)
        .then((res) => res.json())
        .then((data) => {
            if(data.customers.data.length == 0){
                setMessage("¡Este email no existe!")
                setOpenModal(false)
            }else{
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        customer : data.customers.data[0].id
                    })
                };
            
                fetch("https://raknuuaback.onrender.com/delete_subscriptions", requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    setMessage("Te has desuscrito de los servicios. ¡Hasta la próxima!")
                    setOpenModal(false)
                })
            }
        })
    }

    return <><div className="desuscribe">
        <input required="required" placeholder="E-mail" type="email" onChange={(e)=>setEmail(e.target.value)} ref={emailRef}/>
        <button onClick={()=> validate()}>Desuscribirse</button>
        <div className="desuscribeMessage">{message}</div>
       
    </div>
     <ReactModal isOpen = {openModal} className="desuscribeModal">
     ¿Estas seguro de que quieres desuscribirte?
     <div  className="desuscribeModalDiv">
         <button onClick={()=>desuscribirse()}>Si</button>
         <button onClick={()=>setOpenModal(false)}>No</button>
     </div>
 </ReactModal>
 </>    
}

export default Desubscribe;