import React, { useEffect, useState } from "react";
import "./products.css"
import { Link } from "react-router-dom";


function Products(props){
    const [data, setData] = useState(null)
    const [prices, setPrices]= useState([])

    useEffect(() => {
        fetch("https://raknuuaback.onrender.com/products")
        .then((res) => res.json())
        .then((data) => {
            setData(data.products)
            data.products.data.map((element, index) => {
                console.log(element.default_price)
                    
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        price:element.default_price
                    })
                };
        
                fetch("https://raknuuaback.onrender.com/price", requestOptions)
                .then((res) => res.json())
                .then((data) => setPrices(prev=>[...prev, (data.price.unit_amount/100)]))
            })
        });
    }, []);
    console.log(prices)
    
    

    return <div className="productsMain">
        <Link to="/desubscribe"><div className="desubscribe">Desuscribirse</div></Link>
        <div className = "products">
        {
            data ? data.data.map((element, index) => {
                const link = "/pay/"+element.id

                return <div className = "productCard">
                    <div>
                        <img className="productImage" src={element.images[0]} />
                    </div>
                    <div className="productName">{element.name}</div>
                    <div className="productDescription">{element.description}</div>
                    {prices.length>0 &&
                        <div className="productPrice">{prices[index]}€ / mes</div>
                    }
                    <Link to={link}>
                        <button className="productButton">Suscribirse</button>
                    </Link>
                    
                </div>
}

):  <div className="spinner-container">
        <div className="loading-spinner">
        </div>
    </div>
        }
    </div>
    </div>
}

export default Products;