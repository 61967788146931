import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Products from "./products";
import Pay from "./pay";
import Success from "./success";
import Fail from "./fail";
import Desubscribe from "./desubscribe";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Products />}/>
        <Route path="/pay/:id" element={<Pay />}/>
        <Route path="/success" element={<Success />}/>
        <Route path="/fail" element={<Fail />}/>
        <Route path="/desubscribe" element={<Desubscribe />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
