import React from "react";
import "./success.css"

function Fail(props){

    return <div className="message">
        ¡Ups! algo ha ido mal 
    </div>
}

export default Fail;