import React, { createRef, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import './pay.css'

function Pay(props){
    const navigate = useNavigate();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [number, setNumber] = useState();
    const [mmyy, setMMYY] = useState();
    const [cvc, setCVC] = useState();

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const numberRef = useRef(null);
    const mmyyRef = useRef(null);
    const cvcRef = useRef(null);

    const {id} = useParams();
    const [data, setData] = useState()
    const [price, setPrice] = useState()
    useEffect(()=>{
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                product : id
            })
        };
    
        fetch("https://raknuuaback.onrender.com/product", requestOptions)
        .then((res) => {
            if(res.ok){
                return res.json()
            }else{
                console.log("error")
                navigate("/fail")
            }
        })
        .then((data) => {
            setData(data.product)
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    price : data.product.default_price
                })
            };
        
            fetch("https://raknuuaback.onrender.com/price", requestOptions)
            .then((res) => res.json())
            .then((data) => {
                setPrice(data.price.unit_amount/100)
                
            })
        })
    }, [])

    function createCustomer(){
    
        console.log(nameRef.current.checkValidity())
        if(!nameRef.current.checkValidity()){
            nameRef.current.setAttribute('aria-invalid', nameRef.current.reportValidity());
        }else if(!emailRef.current.checkValidity()){
            emailRef.current.setAttribute('aria-invalid', emailRef.current.reportValidity());
        }else if(!numberRef.current.checkValidity()){
            numberRef.current.setAttribute('aria-invalid', numberRef.current.reportValidity());
        }else if(!mmyyRef.current.checkValidity()){
            mmyyRef.current.setAttribute('aria-invalid', mmyyRef.current.reportValidity());
        }else if(!cvcRef.current.checkValidity()){
            cvcRef.current.setAttribute('aria-invalid', cvcRef.current.reportValidity());
        }else{

            try{
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        email : email
                    })
                };
            
                fetch("https://raknuuaback.onrender.com/customer", requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if(data.customers.data.length == 0){
                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                number : number,
                                month:String(mmyy).slice(0,2),
                                year:2000+parseInt(String(mmyy).slice(2)),
                                cvc:cvc
                            })
                        };
                        console.log(mmyy)
                        console.log(String(mmyy).slice(0,2))
                        console.log(2000+parseInt(String(mmyy).slice(2)))
                        console.log("A")
                    
                        fetch("https://raknuuaback.onrender.com/create-payment-method", requestOptions)
                        .then(async (res) => {
                            const json = await res.json()
                            console.log(json)
                            const paymentMethod = json.paymentMethod.id
                
                            const requestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'}
                            }
                            console.log("B")
                            fetch("https://raknuuaback.onrender.com/create-customer", requestOptions)
                            .then(async(res)=>{
                                const json = await res.json();
                                const customer = json.customer.id
                
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {'Content-Type': 'application/json'},
                                    body: JSON.stringify({
                                        customer : customer,
                                        paymentMethod : paymentMethod,
                                        email: email,
                                        name: name,
                                        phone:phone
                                    })
                                };
                            
                                fetch("https://raknuuaback.onrender.com/update-customer", requestOptions)
                                .then(async (res) => {
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {'Content-Type': 'application/json'},
                                        body: JSON.stringify({
                                            customer : customer,
                                            paymentMethod: paymentMethod,
                                            product: id
                                        })
                                    };
                                    console.log("D")
                                    fetch("https://raknuuaback.onrender.com/cre-subscription", requestOptions)
                                    .then(async (res) => {
                                        if(res.ok){
                                            const json = await res.json();
                                            console.log(json)
                                            navigate("/success")
                                        }else{
                                            console.log("error")
                                            navigate("/fail")
                                        }
                                    }, (error) => {
                                        if (error) {
                                            navigate("/fail")
                                        }
                                      })
                                })
                            })
                        })
                    }else{
                        const customer = data.customers.data[0].id

                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                number : number,
                                month:String(mmyy).slice(0,2),
                                year:2000+parseInt(String(mmyy).slice(2)),
                                cvc:cvc
                            })
                        };
                        console.log(mmyy)
                        console.log(String(mmyy).slice(0,2))
                        console.log(2000+parseInt(String(mmyy).slice(2)))
                        console.log("A")
                    
                        fetch("https://raknuuaback.onrender.com/create-payment-method", requestOptions)
                        .then(async (res) => {
                            const json = await res.json()
                            const paymentMethod = json.paymentMethod.id

                            const requestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    customer : customer,
                                    paymentMethod : paymentMethod,
                                    email: email,
                                    name: name,
                                    phone:phone
                                })
                            };
                        
                            fetch("https://raknuuaback.onrender.com/update-customer", requestOptions)
                            .then(async (res) => {
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {'Content-Type': 'application/json'},
                                    body: JSON.stringify({
                                        customer : data.customers.data[0].id
                                    })
                                };
                                
                            
                                fetch("https://raknuuaback.onrender.com/delete_subscriptions", requestOptions)
                                .then((res) => res.json())
                                .then((data) => {
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {'Content-Type': 'application/json'},
                                        body: JSON.stringify({
                                            customer : customer,
                                            product: id
                                        })
                                    };
                                    fetch("https://raknuuaback.onrender.com/cre-subscription", requestOptions)
                                    .then(async (res) => {
                                        if(res.ok){
                                            const json = await res.json();
                                            navigate("/success")
                                        }else{
                                            console.log("error")
                                            navigate("/fail")
                                        }
                                       
                                    },(error) => {
                                        if (error) {
                                            navigate("/fail")
                                        }
                                      })
                                })
                            })
                        })
    
                        
    
                        
                    }
                })
            }catch{
                console.log("error")
                navigate("/fail")
            }

            
        }

        
    }

    return <div className="payPage">
        {data!=null && <div className="payProduct">
            <img src={data.images[0]}/>
            <div className="payProductText">
                <div>{data.name}</div>
                <div className="payProductPrice">{price}€ / mes</div>
            </div>
        </div>}
        <div className = "pay">
            <input required="required" className = "payName" placeholder="Nombre y apellidos"  onChange={(e)=>setName(e.target.value)} ref={nameRef}/>
            <input required="required" className = "payEmail" placeholder="E-mail" type="email" onChange={(e)=>setEmail(e.target.value)} ref={emailRef}/>
            <input required="required" className = "payPhone" minLength="9" maxLength="9"  pattern="\d*" placeholder="Numero de teléfono" type="tel" onChange={(e)=>setPhone(e.target.value)} ref={phoneRef}/>

            <input required="required" className = "payNumber" minLength="16" maxLength="16"  pattern="\d*" placeholder="Numero de tarjeta" type="tel" onChange={(e)=>setNumber(e.target.value)} ref={numberRef}/>
            <input required="required" className = "payMMYY" minLength="4" maxLength="4"  pattern="\d*" placeholder="MM/YY" type="tel" onChange={(e)=>setMMYY(e.target.value)} ref={mmyyRef}/>
            <input required="required" className = "payCVC" minLength="3" maxLength="3" pattern="\d*" placeholder="CVC" type="tel" onChange={(e)=>setCVC(e.target.value)} ref={cvcRef}/>
            
            <button className = "payButton" onClick={()=>createCustomer()}>Suscribirse</button>
        </div>
    </div>
}



export default Pay;