import React from "react";
import "./success.css"

function Success(props){

    return <div className="message">
        ¡Felicidades! Tu suscripción ha sido completada. 😊
    </div>
}

export default Success;